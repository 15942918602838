<template>
  <div class="row-table-product-lite" v-if="productData" @click="onClickDirect">
    <div class="row-product-thumbnail">
      <img :src="productData.url_thumbnail" alt="Ảnh sản phẩm">
    </div>
    <div class="row-product-content">
      <p>{{productData.product_name}}</p>
      <p class="d-flex justify-space-between">
        <span><span class="content-title">Giá bán:</span> <span class="content-value">{{productData.price | formatPrice}}</span></span>
        <span :class="{'high-light': hightLight === 'order_count'}"><span class="content-title">Đã bán {{durationDay}} ngày:</span> <span class="content-value">{{orderCount}}</span></span>
      </p>
      <p>
        <span :class="{'high-light': hightLight === 'order_revenue'}"><span class="content-title">Doanh thu {{durationDay}} ngày:</span> <span class="content-value">{{orderRevenue | formatPrice}}</span></span>
      </p>
    </div>
  </div>
</template>

<script>
import {formatCurrency} from "@/helper/FormatHelper";
import {toNumber} from "@/helper/StringHelper";

export default {
  name: "RowTableProductLite",
  props: {
    productData: {
      required: true
    },
    hightLight: {
      default: 'order_count'
    }
  },
  data() {
    let durationDay = toNumber(Object.getOwnPropertyNames(this.productData).find(key => key.includes('order_revenue')))
    return {
      durationDay: durationDay
    }
  },
  computed: {
    orderRevenue() {
      return this.productData[`order_revenue_${this.durationDay}d`]
    },
    orderCount() {
      return this.productData[`order_count_${this.durationDay}d`]
    }
  },
  filters: {
    formatPrice: function (orderCount) {
      if (orderCount == null || orderCount === '') {
        return ''
      }
      return formatCurrency(orderCount)
    }
  },
  methods: {
    onClickDirect() {
      window.open(this.productData.url_direct, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .row-table-product-lite {
    width: 100%;
    height: 60px;
    overflow: hidden;
    display: inline-flex;
    padding: 4px;
    box-sizing: content-box;
    border-bottom: 1px solid #e8e8e8;

    .row-product-thumbnail {
      margin-right: 4px;

      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
      }
    }

    .row-product-content {
      width: calc(100% - 64px);
      display: block;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .content-title {
          font-size: 8px;
        }

        .content-value {
          font-size: 12px;
        }

        .high-light {
          color: $--color-primary;

          .content-value {
            font-weight: 700;
          }
        }
      }
    }
  }
</style>
