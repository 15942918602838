import { render, staticRenderFns } from "./BtnInstallTool.vue?vue&type=template&id=6ef17fc8&scoped=true&"
import script from "./BtnInstallTool.vue?vue&type=script&lang=js&"
export * from "./BtnInstallTool.vue?vue&type=script&lang=js&"
import style0 from "./BtnInstallTool.vue?vue&type=style&index=0&id=6ef17fc8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef17fc8",
  null
  
)

export default component.exports