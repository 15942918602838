<template>
  <a-button @click="onShareReport" :type="type" size="large" icon="share-alt" :ghost="isGhost">
    <span>{{text}}</span>
    <PopupShareCustomQuery :url="shareLink"/>
  </a-button>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import PopupShareCustomQuery from "@/views/popup/PopupShareCustomQuery";

export default {
  name: "BtnSharelink",
  components: {
    PopupShareCustomQuery
  },
  props: {
    type: {
      default: 'default'
    },
    text: {
      default: 'Chia sẻ'
    },
    url: {
      default: document.location.href
    },
    isGhost: {
      default: false
    }
  },
  methods: {
    async onShareReport() {
      this.$store.dispatch(`GeneralModule/${MutationGeneral.setShowPopupShareLink}`, true)
    },
  },
  computed: {
    shareLink() {
      return new URL(this.url).origin + new URL(this.url).pathname
    }
  }
}
</script>

<style scoped>
  .ant-btn-background-ghost {
    background-color: white !important;
  }
</style>
