<template>
  <div class="nav-wrapper">
    <!--    <nav class="nav" v-bind:class="{ stick: !isAffix || isNavSticky }">-->
    <nav class="nav stick">
      <div class="container">
        <div class="ant-row">
          <div class="ant-col ant-col-xs-22 ant-col-sm-12">
            <a :href="websitePrefix" class="home-logo">
              <img :src="urlLogo" alt="logo" style="height: 32px;width: auto;margin-right: 5px;transform: translateY(-4px);">
              {{ BRAND_NAME }}
              <span>{{SOLOGAN}}</span>
            </a>
          </div>
          <div class="ant-col ant-col-xs-6 ant-col-sm-12">
            <div class="nav-items">
              <BtnShareLink v-if="isInsightReportPage" type="primary" text="Chia sẻ phân tích"/>
              <BtnInstallTool extraClass="d-none d-md-inline-block" v-else/>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

  import BtnInstallTool from './BtnInstallTool'
  import { getUrlGPhotoSize } from '@/helper/GPhotoHelper'
  import { LogoConstants } from '@/constant/beecost/BeeCostResourceConstant'
  import { websitePrefix } from '@/config/ConfigProject'
  import BtnShareLink from "@/components/button/BtnShareLink";
  import {BRAND_NAME, SOLOGAN} from "@/constant/general/GeneralConstant";

  export default {
    name: 'Nav',
    components: {
      BtnInstallTool,
      BtnShareLink
    },
    props: {
      affix: Boolean
    },
    data: function () {
      return {
        websitePrefix: websitePrefix,
        urlLogo: getUrlGPhotoSize(LogoConstants.bee_market, '32'),
        isAffix: this.affix,
        isNavSticky: false,
        BRAND_NAME: BRAND_NAME,
        SOLOGAN: SOLOGAN,
        isInsightReportPage: this.$route.name === 'custom_market_view'
      }
    },
    async mounted() {
      if (this.affix) {
        window.addEventListener('scroll', this.handleScroll)
        window.scroll()
      }

    },
    methods: {
      handleScroll() {
        let banner = document.getElementById('banner')
        if (banner != null) {
          this.isNavSticky = window.scrollY >= (banner.offsetHeight + banner.offsetTop)
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "../../assets/styles/variables";

  .banner-vaonet {
    position: fixed;
    z-index: 100;
    top: 67px;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .nav {
    height: 90px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .btn-link {
    color: #505050;
  }

  .nav-items {
    text-align: right;
    padding: 27px 0;

    > a {
      font-size: 12px;
      text-decoration: none !important;
      font-weight: bold;
    }

    .btn-add-beecost {
      margin-right: 40px;
    }
  }

  .btn-add-beecost {
    display: none;
  }

  .home-logo {
    font-size: 22px;
    font-weight: bold;
    line-height: 90px;
    display: inline-block;
    /*color: #fff;*/
    color: #505050;
    height: 90px;
    text-decoration: none !important;

    padding-left: 38px;

    span {
      /*color: #505050;*/
      color: #fff;
    }
  }

  .nav.stick {
    position: fixed;
    height: 67px;
    border-bottom: 1px solid #eee;
    background: #fff;

    .home-logo {
      color: $beeOrange;
      height: 67px;
      line-height: 67px;

      span {
        color: #505050;
      }
    }

    .nav-items {
      padding: 17px 0;
    }

    .btn-add-beecost {
      display: inline-block;
      padding: 10px 25px;
      font-size: 14px;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }

  @media (max-width: 575px) {
    .home-logo {
      font-size: 17px;
    }
  }
</style>
