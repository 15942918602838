<template>
  <a class="btn btn-primary btn-add-beecost" v-bind:class="[{'btn-lg': size === 'lg'}, extraClass]"
     :href="URL_WEB">
    <a-icon type="line-chart"/>
    Phân tích nâng cao
  </a>
</template>

<script>

  import { getUrlGPhotoSize } from '@/helper/GPhotoHelper'
  import { LogoConstants } from '@/constant/beecost/BeeCostResourceConstant'
  import {URL_WEB} from "@/config/ConfigEndpoint";

  export default {
    name: 'BtnInstallTool',
    props: {
      extraClass: String,
      size: String,
    },
    components: {},
    computed: {},
    data: function () {
      return {
        URL_WEB: URL_WEB,
      }
    },
    methods: {}
  }
</script>

<style scoped lang="scss">
  @import "../../assets/styles/beecost_base.css";
  @import "../../assets/styles/override.css";
</style>
