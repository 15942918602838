<template>
  <div class="">
    <div class="d-flex justify-space-between">
      <h2 class="title">
        <span class="border-left-line"></span>
        <!--        <a-icon type="shop"/>-->
        Phân tích thị trường theo {{tabStatistic ==='order_count' ? 'Số sản phẩm đã bán' : 'Doanh số'}}
      </h2>

      <a-button v-if="actionName==='HOME_PAGE'" type="primary" size="large" icon="line-chart" ghost
                @click="onSearchAdvanced">
        Phân tích nâng cao
      </a-button>
      <BtnSearchFilter v-if="isInsightReportPage"/>
    </div>

    <a-tabs v-model="tabStatistic" size="large" type="card" class="tab-filter-option" @change="onChangetab">
      <a-tab-pane key="commission" v-if="partnerType && partnerType === PARTNER_TYPE.affiliate && hasCommissionData">
        <span slot="tab">
          <a-icon type="dollar"/>
          Hoa hồng Marketing
        </span>
        <BarChart v-if="aggCommissionByCategories!= null && showChart.isShowCategoriesChart" class="market-chart" color="#096dd9"
                  :title="aggCommissionByCategories['title']"
                  :sub-title="aggCommissionByCategories['subTitle']"
                  :categories-name="aggCommissionByCategories['categoriesName']"
                  :category-title="aggCommissionByCategories['categoryTitle']"
                  :series-data="aggCommissionByCategories['seriesData']"
                  :series-title="aggCommissionByCategories['seriesTitle']"
                  :series-comparison-data="comparisonAggCommissionByCategories ? comparisonAggCommissionByCategories['seriesData'] : null"
                  :series-comparison-title="comparisonAggCommissionByCategories ? comparisonAggCommissionByCategories['subTitle'] : null"
        />

        <ColumnChart v-if="aggCommissionByPriceRange!= null && showChart.isShowPriceRangeChart" class="market-chart"
                     color="#ff754a"
                     :title="aggCommissionByPriceRange['title']"
                     :sub-title="aggCommissionByPriceRange['subTitle']"
                     :categories-name="aggCommissionByPriceRange['categoriesName']"
                     :category-title="aggCommissionByPriceRange['categoryTitle']"
                     :series-data="aggCommissionByPriceRange['seriesData']"
                     :series-title="aggCommissionByPriceRange['seriesTitle']"
                     :series-comparison-data="comparisonAggCommissionByPriceRange ? comparisonAggCommissionByPriceRange['seriesData'] : null"
                     :series-comparison-title="comparisonAggCommissionByPriceRange ? comparisonAggCommissionByPriceRange['subTitle'] : null"
        />

        <a-row :gutter="24" type="flex" align="middle">
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="aggCommissionByBrands!= null && showChart.isShowBrandsChart">

            <PieChart class="market-chart"
                      color="#ff754a"
                      :title="aggCommissionByBrands['title']"
                      :sub-title="aggCommissionByBrands['subTitle']"
                      :categories-name="aggCommissionByBrands['categoriesName']"
                      :category-title="aggCommissionByBrands['categoryTitle']"
                      :series-data="aggCommissionByBrands['seriesData']"
                      :series-title="aggCommissionByBrands['seriesTitle']"
                      :series-comparison-data="comparisonAggCommissionByBrands ? comparisonAggCommissionByBrands['seriesData'] : null"
                      :series-comparison-title="comparisonAggCommissionByBrands ? comparisonAggCommissionByBrands['subTitle'] : null"
                      :number-total="aggCommissionByBrands['countTotal']"
                      :number-charting="aggCommissionByBrands['countCharting']"
            />
          </a-col>

          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="aggCommissionByLocations!= null && showChart.isShowLocationsChart">
            <PieChart class="market-chart"
                      color="#ff754a"
                      :title="aggCommissionByLocations['title']"
                      :sub-title="aggCommissionByLocations['subTitle']"
                      :categories-name="aggCommissionByLocations['categoriesName']"
                      :category-title="aggCommissionByLocations['categoryTitle']"
                      :series-data="aggCommissionByLocations['seriesData']"
                      :series-title="aggCommissionByLocations['seriesTitle']"
                      :series-comparison-data="comparisonAggCommissionByLocations ? comparisonAggCommissionByLocations['seriesData'] : null"
                      :series-comparison-title="comparisonAggCommissionByLocations ? comparisonAggCommissionByLocations['subTitle'] : null"
            />
          </a-col>
        </a-row>

      </a-tab-pane>
      <a-tab-pane key="revenue" v-if="hasRevenueData">
        <span slot="tab">
          <a-icon type="dollar"/>
          Doanh số
        </span>
        <BarChart v-if="(!partnerType || partnerType === PARTNER_TYPE.delivery) && showChart.isShowCategoriesChart && aggRevenueByCategories!= null" class="market-chart" color="#096dd9"
                  :title="aggRevenueByCategories['title']"
                  :sub-title="aggRevenueByCategories['subTitle']"
                  :categories-name="aggRevenueByCategories['categoriesName']"
                  :category-title="aggRevenueByCategories['categoryTitle']"
                  :series-data="aggRevenueByCategories['seriesData']"
                  :series-title="aggRevenueByCategories['seriesTitle']"
                  :series-comparison-data="comparisonAggRevenueByCategories ? comparisonAggRevenueByCategories['seriesData'] : null"
                  :series-comparison-title="comparisonAggRevenueByCategories ? comparisonAggRevenueByCategories['subTitle'] : null"
        />

        <ColumnChart v-if="(!partnerType || partnerType === PARTNER_TYPE.delivery) && showChart.isShowPriceRangeChart && aggRevenueByPriceRange!= null" class="market-chart"
                     color="#ff754a"
                     :title="aggRevenueByPriceRange['title']"
                     :sub-title="aggRevenueByPriceRange['subTitle']"
                     :categories-name="aggRevenueByPriceRange['categoriesName']"
                     :category-title="aggRevenueByPriceRange['categoryTitle']"
                     :series-data="aggRevenueByPriceRange['seriesData']"
                     :series-title="aggRevenueByPriceRange['seriesTitle']"
                     :series-comparison-data="comparisonAggRevenueByPriceRange ? comparisonAggRevenueByPriceRange['seriesData'] : null"
                     :series-comparison-title="comparisonAggRevenueByPriceRange ? comparisonAggRevenueByPriceRange['subTitle'] : null"
        />

        <a-row v-if="(!partnerType || partnerType === PARTNER_TYPE.delivery)" :gutter="24" type="flex" align="middle">
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="aggRevenueByBrands!= null && showChart.isShowBrandsChart">
            <PieChart class="market-chart"
                      color="#ff754a"
                      :title="aggRevenueByBrands['title']"
                      :sub-title="aggRevenueByBrands['subTitle']"
                      :categories-name="aggRevenueByBrands['categoriesName']"
                      :category-title="aggRevenueByBrands['categoryTitle']"
                      :series-data="aggRevenueByBrands['seriesData']"
                      :series-title="aggRevenueByBrands['seriesTitle']"
                      :series-comparison-data="comparisonAggRevenueByBrands ? comparisonAggRevenueByBrands['seriesData'] : null"
                      :series-comparison-title="comparisonAggRevenueByBrands ? comparisonAggRevenueByBrands['subTitle'] : null"
                      :number-total="aggRevenueByBrands['countTotal']"
                      :number-charting="aggRevenueByBrands['countCharting']"
            />
          </a-col>
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="aggRevenueByLocations!= null && showChart.isShowLocationsChart">
            <PieChart class="market-chart"
                      color="#ff754a"
                      :title="aggRevenueByLocations['title']"
                      :sub-title="aggRevenueByLocations['subTitle']"
                      :categories-name="aggRevenueByLocations['categoriesName']"
                      :category-title="aggRevenueByLocations['categoryTitle']"
                      :series-data="aggRevenueByLocations['seriesData']"
                      :series-title="aggRevenueByLocations['seriesTitle']"
                      :series-comparison-data="comparisonAggRevenueByLocations ? comparisonAggRevenueByLocations['seriesData'] : null"
                      :series-comparison-title="comparisonAggRevenueByLocations ? comparisonAggRevenueByLocations['subTitle'] : null"
            />
          </a-col>
        </a-row>
        <div v-if="partnerType && partnerType === PARTNER_TYPE.affiliate" class="advance-feature">
          <h2 class="title">Tính năng này được cung cấp bởi <a :href="URL_WEB">Metric Ecom Việt</a></h2>
          <a-button size="large" type="primary" @click="goToBeecost">Tới Metric Ecom Việt</a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="order_count"  v-if="hasOrderCountData">
        <span slot="tab">
          <a-icon type="tag"/>
          Sản phẩm đã bán
        </span>
        <BarChart v-if="(!partnerType || partnerType === PARTNER_TYPE.delivery) && showChart.isShowCategoriesChart && aggOrderCountByCategories!= null" class="market-chart" color="#096dd9"
                  :title="aggOrderCountByCategories['title']"
                  :sub-title="aggOrderCountByCategories['subTitle']"
                  :categories-name="aggOrderCountByCategories['categoriesName']"
                  :category-title="aggOrderCountByCategories['categoryTitle']"
                  :series-data="aggOrderCountByCategories['seriesData']"
                  :series-title="aggOrderCountByCategories['seriesTitle']"
                  :series-comparison-data="comparisonAggOrderCountByCategories ? comparisonAggOrderCountByCategories['seriesData'] : null"
                  :series-comparison-title="comparisonAggOrderCountByCategories ? comparisonAggOrderCountByCategories['subTitle'] : null"
        />

        <ColumnChart v-if="(!partnerType || partnerType === PARTNER_TYPE.delivery) && showChart.isShowPriceRangeChart && aggOrderCountByPriceRange!= null" class="market-chart"
                     color="#ff754a"
                     :title="aggOrderCountByPriceRange['title']"
                     :sub-title="aggOrderCountByPriceRange['subTitle']"
                     :categories-name="aggOrderCountByPriceRange['categoriesName']"
                     :category-title="aggOrderCountByPriceRange['categoryTitle']"
                     :series-data="aggOrderCountByPriceRange['seriesData']"
                     :series-title="aggOrderCountByPriceRange['seriesTitle']"
                     :series-comparison-data="comparisonAggOrderCountByPriceRange ? comparisonAggOrderCountByPriceRange['seriesData'] : null"
                     :series-comparison-title="comparisonAggOrderCountByPriceRange ? comparisonAggOrderCountByPriceRange['subTitle'] : null"
        />

        <a-row v-if="(!partnerType || partnerType === PARTNER_TYPE.delivery)" :gutter="24" type="flex" align="middle">
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="aggOrderCountByBrands!= null && showChart.isShowBrandsChart">

            <PieChart class="market-chart"
                      color="#ff754a"
                      :title="aggOrderCountByBrands['title']"
                      :sub-title="aggOrderCountByBrands['subTitle']"
                      :categories-name="aggOrderCountByBrands['categoriesName']"
                      :category-title="aggOrderCountByBrands['categoryTitle']"
                      :series-data="aggOrderCountByBrands['seriesData']"
                      :series-title="aggOrderCountByBrands['seriesTitle']"
                      :series-comparison-data="comparisonAggOrderCountByBrands ? comparisonAggOrderCountByBrands['seriesData'] : null"
                      :series-comparison-title="comparisonAggOrderCountByBrands ? comparisonAggOrderCountByBrands['subTitle'] : null"
                      :number-total="aggOrderCountByBrands['countTotal']"
                      :number-charting="aggOrderCountByBrands['countCharting']"
            />
          </a-col>

          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="aggOrderCountByLocations!= null && showChart.isShowLocationsChart">
            <PieChart class="market-chart"
                      color="#ff754a"
                      :title="aggOrderCountByLocations['title']"
                      :sub-title="aggOrderCountByLocations['subTitle']"
                      :categories-name="aggOrderCountByLocations['categoriesName']"
                      :category-title="aggOrderCountByLocations['categoryTitle']"
                      :series-data="aggOrderCountByLocations['seriesData']"
                      :series-title="aggOrderCountByLocations['seriesTitle']"
                      :series-comparison-data="comparisonAggOrderCountByLocations ? comparisonAggOrderCountByLocations['seriesData'] : null"
                      :series-comparison-title="comparisonAggOrderCountByLocations ? comparisonAggOrderCountByLocations['subTitle'] : null"
            />
          </a-col>
        </a-row>

        <div v-if="partnerType && partnerType === PARTNER_TYPE.affiliate" class="advance-feature">
          <h2 class="title">Tính năng này được cung cấp bởi <a :href="URL_WEB">Metric Ecom Việt</a></h2>
          <a-button size="large" type="primary" @click="goToBeecost">Tới Metric Ecom Việt</a-button>
        </div>

      </a-tab-pane >
      <a-tab-pane key="commission" v-if="partnerType === PARTNER_TYPE.delivery && hasCommissionData">
        <span slot="tab">
          <a-icon type="dollar"/>
          Hoa hồng Marketing
        </span>
        <BarChart v-if="(!partnerType || partnerType === PARTNER_TYPE.affiliate) && showChart.isShowCategoriesChart && aggCommissionByCategories!= null" class="market-chart" color="#096dd9"
                  :title="aggCommissionByCategories['title']"
                  :sub-title="aggCommissionByCategories['subTitle']"
                  :categories-name="aggCommissionByCategories['categoriesName']"
                  :category-title="aggCommissionByCategories['categoryTitle']"
                  :series-data="aggCommissionByCategories['seriesData']"
                  :series-title="aggCommissionByCategories['seriesTitle']"
                  :series-comparison-data="comparisonAggCommissionByCategories ? comparisonAggCommissionByCategories['seriesData'] : null"
                  :series-comparison-title="comparisonAggCommissionByCategories ? comparisonAggCommissionByCategories['subTitle'] : null"
        />

        <ColumnChart v-if="(!partnerType || partnerType === PARTNER_TYPE.affiliate) && showChart.isShowPriceRangeChart && aggCommissionByPriceRange!= null" class="market-chart"
                     color="#ff754a"
                     :title="aggCommissionByPriceRange['title']"
                     :sub-title="aggCommissionByPriceRange['subTitle']"
                     :categories-name="aggCommissionByPriceRange['categoriesName']"
                     :category-title="aggCommissionByPriceRange['categoryTitle']"
                     :series-data="aggCommissionByPriceRange['seriesData']"
                     :series-title="aggCommissionByPriceRange['seriesTitle']"
                     :series-comparison-data="comparisonAggCommissionByPriceRange ? comparisonAggCommissionByPriceRange['seriesData'] : null"
                     :series-comparison-title="comparisonAggCommissionByPriceRange ? comparisonAggCommissionByPriceRange['subTitle'] : null"
        />

        <a-row :gutter="24" type="flex" align="middle">
          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class=""
                 v-if="(!partnerType || partnerType === PARTNER_TYPE.affiliate) && showChart.isShowBrandsChart && aggCommissionByBrands!= null">

            <PieChart class="market-chart"
                      color="#ff754a"
                      :title="aggCommissionByBrands['title']"
                      :sub-title="aggCommissionByBrands['subTitle']"
                      :categories-name="aggCommissionByBrands['categoriesName']"
                      :category-title="aggCommissionByBrands['categoryTitle']"
                      :series-data="aggCommissionByBrands['seriesData']"
                      :series-title="aggCommissionByBrands['seriesTitle']"
                      :series-comparison-data="comparisonAggCommissionByBrands ? comparisonAggCommissionByBrands['seriesData'] : null"
                      :series-comparison-title="comparisonAggCommissionByBrands ? comparisonAggCommissionByBrands['subTitle'] : null"
                      :number-total="aggCommissionByBrands['countTotal']"
                      :number-charting="aggCommissionByBrands['countCharting']"
            />
          </a-col>

          <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class=""
                 v-if="(!partnerType || partnerType === PARTNER_TYPE.affiliate) && showChart.isShowLocationsChart && aggCommissionByLocations!= null">
            <PieChart class="market-chart"
                      color="#ff754a"
                      :title="aggCommissionByLocations['title']"
                      :sub-title="aggCommissionByLocations['subTitle']"
                      :categories-name="aggCommissionByLocations['categoriesName']"
                      :category-title="aggCommissionByLocations['categoryTitle']"
                      :series-data="aggCommissionByLocations['seriesData']"
                      :series-title="aggCommissionByLocations['seriesTitle']"
                      :series-comparison-data="comparisonAggCommissionByLocations ? comparisonAggCommissionByLocations['seriesData'] : null"
                      :series-comparison-title="comparisonAggCommissionByLocations ? comparisonAggCommissionByLocations['subTitle'] : null"
            />
          </a-col>
        </a-row>

        <div v-if="partnerType && partnerType === PARTNER_TYPE.delivery" class="advance-feature">
          <h2 class="title">Tính năng này được cung cấp bởi <a :href="URL_WEB">Metric Ecom Việt</a></h2>
          <a-button size="large" type="primary" @click="goToBeecost">Tới Metric Ecom Việt</a-button>
        </div>
      </a-tab-pane>
    </a-tabs>
    <PopupMarketNotification/>
  </div>

</template>

<script>

import TableProduct from '@/views/search/TableProduct'
import MetricRectangle from '@/components/statistic/metric/MetricRectangle'
import { formatCurrency, formatNumberHuman } from '@/helper/FormatHelper'
import SearchFilter from '@/views/search/SearchFilter'
import BarChart from '@/components/chart/BarChart'
import LineChart from '@/components/chart/LineChart'
import ColumnChart from '@/components/chart/ColumnChart'
import PieChart from '@/components/chart/PieChart'
import BtnSearchFilter from "@/components/button/BtnSearchFilter";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import PopupMarketNotification from "@/views/popup/PopupMarketNotification";
import {PARTNER_TYPE} from "@/constant/general/GeneralConstant";
import {mixinGetSearchStatisticAggComparisonResult} from "@/mixin/store/MixinSearchStatisticComparisonResult";
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
import {URL_WEB} from "@/config/ConfigEndpoint";

export default {
  components: {
    BtnSearchFilter,
    SearchFilter,
    TableProduct,
    MetricRectangle,
    BarChart,
    LineChart,
    ColumnChart,
    PieChart,
    PopupMarketNotification
  },
  props: {
    partnerType: {
      default: null
    },
    isComparisonMode: {
      default: false
    },
    isSearchDemoInit: {
      default: false
    },
    showChart: {
      default: function () {
        return {
          isShowCategoriesChart: true,
          isShowPriceRangeChart: true,
          isShowBrandsChart: true,
          isShowLocationsChart: true,
        }
      }
    }
  },
  data() {
    return {
      tabStatistic: this.partnerType === PARTNER_TYPE.affiliate ? 'commission' : 'revenue',
      isInsightReportPage: this.$route.name === 'custom_market_view',
      PARTNER_TYPE: PARTNER_TYPE,
      URL_WEB: URL_WEB,
      listTab: {
        revenue: {
          name: 'revenue',
          isUpdatedData: true
        },
        order_count: {
          name: 'order_count',
          isUpdatedData: this.isSearchDemoInit
        },
        commission: {
          name: 'commission',
          isUpdatedData: this.isSearchDemoInit
        },
      }
    }
  },
  async mounted() {
  },
  created() {
  },
  methods: {
    async onSearchAdvanced() {
      // let options = this.$store.state.SearchFilterModule.options
      // let resultPermission = await canFilterProduct(options)

      // if (resultPermission.isPermission) {
      await this.$router.push({name: 'insight' })
      // } else {
      //   if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
      //     hiển thị popup tới trang market
      // this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
      // } else {
      //   this.$notification['warning']({
      //     message: resultPermission.title,
      //     description: resultPermission.message,
      //   })
      // }
      // }
    },
    async onChangetab(key) {
      this.$emit('changeTab', key)
      if (
        (key === 'commission' && this.partnerType === PARTNER_TYPE.delivery)
        || (key !== 'commission' && this.partnerType === PARTNER_TYPE.affiliate)
      ) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupMarketNotification}`, true)
      }
      if (key !== 'revenue' && !this.listTab[key].isUpdatedData) {
        let optionsFilter = this.$store.state.SearchFilterModule.options
        let optionsStatistic = []
        switch (key) {
          case 'order_count':
            optionsStatistic = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_ORDER_COUNT_STATISTIC}`, optionsFilter)
            break
        }
        if (optionsStatistic?.length) {
          let options = {
            ...optionsFilter,
            'statistics': [
              ...optionsStatistic
            ]
          }
          options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT
          await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isDemo}`, !this.isLogin)
          await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
        }
      }
      if (key !== 'revenue' && !this.listTab[key].isUpdatedData && this.isComparisonMode) {
        let optionsFilter = this.$store.state.SearchFilterModule.options
        let durationRange = this.iDurationRangeComparison
        if (optionsFilter.durationDay) {
          let optionsText = JSON.stringify(optionsFilter)
          optionsText = optionsText.replaceAll(`${optionsFilter.durationDay}d`, 'custom')
          optionsText = optionsText.replaceAll(`${optionsFilter.durationDay}D`, 'CUSTOM')
          optionsFilter = JSON.parse(optionsText)
        }
        optionsFilter = {
          ...optionsFilter,
          'start_date': durationRange[0].format("YYYY MM DD").replaceAll(' ', ''),
          'end_date': durationRange[1].format("YYYY MM DD").replaceAll(' ', ''),
          'durationDay': 0
        }
        let optionsStatistic = []
        switch (key) {
          case 'order_count':
            optionsStatistic = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_ORDER_COUNT_STATISTIC}`, optionsFilter)
            break
        }
        if (optionsStatistic?.length) {
          let options = {
            ...optionsFilter,
            'statistics': [
              ...optionsStatistic
            ]
          }
          options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT_COMPARISON
          await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
        }
      }
      this.listTab[key].isUpdatedData = true
    },
    goToBeecost() {
      window.open(URL_WEB, '_top')
    }
  },
  computed: {
    iDurationRangeComparison: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationRangeComparison
      }
    },
    actionName: {
      get() {
        return this.$store.state.GeneralModule.actionName
      }
    },
    isClickedSearched: {
      get() {
        return this.$store.state.SearchFilterModule.isClickedSearched
      }
    },
    isLoading: {
      get() {
        return this.$store.state.SearchFilterModule.isLoading
      }
    },
    orderTotal: {
      get() {
        return this.$store.state.SearchStatisticResultModule.orderTotal
      }
    },
    revenueTotal: {
      get() {
        return this.$store.state.SearchStatisticResultModule.revenueTotal
      }
    },
    productTotal: {
      get() {
        return this.$store.state.SearchStatisticResultModule.productTotal
      }
    },
    shopTotal: {
      get() {
        return this.$store.state.SearchStatisticResultModule.shopTotal
      }
    },
    aggRevenueByCategories: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggRevenueByCategories
      }
    },
    aggRevenueByPriceRange: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggRevenueByPriceRange
      }
    },
    aggRevenueByBrands: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggRevenueByBrands
      }
    },
    aggRevenueByLocations: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggRevenueByLocations
      }
    },
    aggOrderCountByCategories: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggOrderCountByCategories
      }
    },
    aggOrderCountByPriceRange: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggOrderCountByPriceRange
      }
    },
    aggOrderCountByBrands: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggOrderCountByBrands
      }
    },
    aggOrderCountByLocations: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggOrderCountByLocations
      }
    },
    aggCommissionByCategories: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggCommissionByCategories
      }
    },
    aggCommissionByPriceRange: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggCommissionByPriceRange
      }
    },
    aggCommissionByBrands: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggCommissionByBrands
      }
    },
    aggCommissionByLocations: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggCommissionByLocations
      }
    },
    isLogin: {
      get() {
        return this.$store.state.UserModule.isLogin
      }
    },
    hasRevenueData() {
      return this.aggRevenueByCategories!= null
        || this.aggRevenueByPriceRange!= null
        || this.aggRevenueByBrands!= null
        || this.aggRevenueByLocations!= null
    },
    hasOrderCountData() {
      return this.aggOrderCountByCategories!= null
        || this.aggOrderCountByPriceRange!= null
        || this.aggOrderCountByBrands!= null
        || this.aggOrderCountByLocations!= null
    },
    hasCommissionData() {
      return this.aggCommissionByCategories!= null
        || this.aggCommissionByPriceRange!= null
        || this.aggCommissionByBrands!= null
        || this.aggCommissionByLocations!= null
    }
  },
  mixins: [
    mixinGetSearchStatisticAggComparisonResult
  ],
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
  },
}
</script>

<style lang="scss">

.tab-filter-option {
  .ant-tabs-nav {
    display: flex;
    justify-content: space-between
  }
}

</style>

<style scoped lang="scss">
.market-chart {
  padding: 20px;
  border: solid 0.5px #cfd8dc66;
  margin-top: 20px;
  margin-bottom: 20px;
}

.advance-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
