<template>
  <div class="layout-homepage">
    <slot></slot>
  </div>

</template>

<script>

  export default {
    components: {},
    async mounted() {
    }

  }
</script>
