<template>
  <div class="container-bg-white">
    <strong class="summary-title">Tiêu chí chọn lọc:</strong>
    <ul>
      <!--    <div class="d-flex justify-space-between">-->
      <!--      <h1 class="title">Danh sách hàng bán chạy trong <strong>{{durationDay}} ngày gần nhất</strong>:</h1>-->
      <!--      <BtnShareLink type="primary" :is-ghost="true" text="Chia sẻ phân tích" v-if="isBigScreen"/>-->
      <!--    </div>-->
      <li class="report-property">
        <span class="property-title">Thời gian</span>:
        <template v-if="durationDay">
          <a-tag>{{durationDay}} ngày</a-tag>
        </template>
        <a-tag v-else>Tất cả</a-tag>
        <a class="link-to-insight" @click="onClickAddFilter">+ Thay đổi thời gian</a>
      </li>
      <li class="report-property">
        <span class="property-title">Từ khoá sản phẩm</span>:
        <template v-if="listKeyword && listKeyword.length">
          <a-tag v-for="keyword in listKeyword" :key="keyword">{{keyword}}</a-tag>
        </template>
        <a-tag v-else>Tất cả</a-tag>
        <a class="link-to-insight" @click="onClickAddFilter">+ Thêm từ khoá</a>
      </li>
      <li class="report-property">
        <span class="property-title">Ngành hàng</span>:
        <template  v-if="listCategory && listCategory.length">
          <a-tag v-for="category in listCategory" :key="category">{{category}}</a-tag>
        </template>
        <a-tag v-else>Tất cả</a-tag>
        <a class="link-to-insight" @click="onClickAddFilter">+ Thêm danh mục</a>
      </li>
      <li class="report-property">
        <span class="property-title">Thương hiệu</span>:
        <template v-if="listBrand && listBrand.length">
          <a-tag v-for="brand in listBrand" :key="brand">{{brand}}</a-tag>
        </template>
        <a-tag v-else>Tất cả</a-tag>
        <a class="link-to-insight" @click="onClickAddFilter">+ Thêm thương hiệu</a>
      </li>
      <li class="report-property">
        <span class="property-title">Nơi bán (kho hàng)</span>:
        <template v-if="listLocation && listLocation.length">
          <a-tag v-for="location in listLocation" :key="location">{{location}}</a-tag>
        </template>
        <a-tag v-else>Tất cả</a-tag>
        <a class="link-to-insight" @click="onClickAddFilter">+ Thêm nơi bán</a>
      </li>
    </ul>
  </div>
</template>

<script>
import {ALL_CATEGORIES_BY_PLATFORM} from "@/constant/product/ProductCategoriesConstant";
import BtnShareLink from "@/components/button/BtnShareLink";
import {getPlatformById} from "@/service/platform/PlatformCommonService";
import {toNumber} from "@/helper/StringHelper";

export default {
  name: "SearchSummaryQuery",
  components: {
    BtnShareLink,
  },
  props: {
    searchOptions: {
      default: null
    }
  },
  data() {
    return {
      isBigScreen: screen.availWidth > 700
    }
  },
  computed: {
    durationDay() {
      return toNumber(this.searchOptions?.sort_by)
    },
    listKeyword() {
      return this.searchOptions?.queries
    },
    listCategory() {
      if (!this.searchOptions?.categories) {
        return ['Tất cả']
      }
      let platform = getPlatformById(this.iPlatforms[0])
      return this.searchOptions?.categories.map( value => {
        return ALL_CATEGORIES_BY_PLATFORM[platform.uniqueName].find(category => {
          return category.value === value
        })?.label
      })
    },
    listBrand() {
      return this.searchOptions?.brands
    },
    listLocation() {
      return this.searchOptions?.locations
    },
    iPlatforms: {
      get() {
        return this.$store.state.SearchFilterModule.iPlatforms
      }
    },
  },
  methods: {
    async onClickAddFilter() {
      this.$router.push({name: 'market_view'})
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .summary-title {
    font-size: 16px;
  }

  .report-property {
    margin: 6px 0;

    .property-title {
      font-size: 16px;
    }

    .link-to-insight {
      font-size: 12px;
    }
  }
</style>
