<template>
  <div>
    <div class="sort-container">
      <div class="sort-item" :class="{'high-light': sortCriterion === 'order_count'}" @click="sort('order_count')">Sản phẩm đã bán</div>
      <div class="divider-vertical"></div>
      <div class="sort-item" :class="{'high-light': sortCriterion === 'order_revenue'}" @click="sort('order_revenue')">Doanh thu</div>
    </div>
    <div class="table-product-lite" v-if="products && products.length">
      <RowTableProductLite v-for="product in products" :key="product.product_base_id" :product-data="product" :hight-light="sortCriterion"/>
    </div>
  </div>
</template>

<script>
import RowTableProductLite from "@/components/product/RowTableProductLite";
export default {
  name: "TableProductLite",
  components: {RowTableProductLite},
  data() {
    return {
      sortCriterion: 'order_count'
    }
  },
  computed: {
    iDurationDay: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationDay
      }
    },
    products: {
      get() {
        return this.$store.state.SearchProductResultModule.products
      }
    },
  },
  methods: {
    sort(sortCretirion) {
      this.sortCriterion = sortCretirion
      this.products.sort((item1, item2) => {
        return item2[`${sortCretirion}_${this.iDurationDay}d`] - item1[`${sortCretirion}_${this.iDurationDay}d`]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-product-lite {
    width: 100%;
    display: grid;
  }

  .sort-container {
    width: fit-content;
    height: 30px;
    margin: 16px auto;
    display: flex;
    font-size: 14px;

    .divider-vertical {
      height: 100%;
      width: 1px;
      border: #e8e8e8 1px solid;
    }

    .sort-item {
      border-bottom: #e8e8e8 1px solid;
      padding: 4px 8px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .high-light {
      color: $--color-primary;
      font-weight: bold;
      font-size: 16px;
    }
  }
</style>
