<template>
  <div class="custom-market-page">
    <Nav affix/>
    <div class="page-content" style="margin-top:100px">
      <h1 class="page-title">Danh sách mặt hàng online đang bán chạy cho {{keyText}}</h1>
      <p class="page-description">Cập nhật ngày {{new Date().toLocaleDateString('en-GB')}}.</p>
      <p class="page-description">Xin chào, để việc bán hàng của thêm hiệu quả, BeeCost đã lập danh sách và báo cáo các mặt hàng đang bán chạy trên sàn thương mại điện tử cho {{keyText}}.</p>
      <p class="page-description">Bạn cũng có thể xem thêm từ khóa và ngành hàng cho riêng mình từ báo cáo này.</p>
      <strong>Các nội dung chính:</strong>
      <ol class="list-of-content">
        <li>
          <a href="#product_section">Danh sách sản phẩm bán chạy cho {{keyText}}</a>
        </li>
        <li>
          <a href="#revenue_section">Xem tổng sản phẩm, số tiền đã bán được cho {{keyText}}</a>
        </li>
        <li>
          <a href="#price_range_section">Các mức giá sản phẩm phổ biến của {{keyText}}</a>
        </li>
        <li>
          <a href="#brand_section">Các thương hiệu bán chạy đối với {{keyText}}</a>
        </li>
        <li>
          <a href="#location_section">Địa chỉ các shop bán chạy cho {{keyText}}</a>
        </li>
        <li>
          <a href="#download_section">Tải file excel danh sách sản phẩm bán chạy cho {{keyText}}</a>
        </li>
      </ol>
      <strong>Nội dung chi tiết:</strong>
      <h2 id="product_section" class="page-sub-title">Danh sách Sản phẩm bán chạy cho {{keyText}}</h2>
      <SearchSummaryQuery v-if="hash && searchOptions" :search-options="searchOptions"/>
      <div class="container-bg-white container-space-default border-solid">
        <h2 class="title margin-top-25 d-flex justify-space-between">
          <span><span class="border-left-line"></span>Sản phẩm bán chạy</span>
          <BtnSearchFilter/>
        </h2>
        <TableProduct class="" v-if="isBigScreen"/>
        <TableProductLite v-else/>
        <div class="go-to-download-wrap">
          <div class="go-to-download" @click="onClickGoToDownload">Download danh sách</div>
        </div>
      </div>
      <h2 id="revenue_section" class="page-sub-title">Thống kê số sản phẩm, số tiền bán được cho {{keyText}}</h2>
      <SearchSummaryQuery v-if="hash && searchOptions" :search-options="searchOptions"/>
      <div class="container-bg-white container-space-default border-solid">
        <StatisticGeneral/>
      </div>
      <a-spin :spinning="isLoading">
        <div class="container-bg-white container-space-default border-solid">
          <MarketChart :show-chart="{
              isShowCategoriesChart: true,
              isShowPriceRangeChart: false,
              isShowBrandsChart: false,
              isShowLocationsChart: false,
            }"
          />
        </div>
      </a-spin>
      <h2 id="price_range_section" class="page-sub-title">Các mức giá sản phẩm phổ biến của {{keyText}}</h2>
      <SearchSummaryQuery v-if="hash && searchOptions" :search-options="searchOptions"/>
      <a-spin :spinning="isLoading">
        <div class="container-bg-white container-space-default border-solid">
          <MarketChart :show-chart="{
              isShowCategoriesChart: false,
              isShowPriceRangeChart: true,
              isShowBrandsChart: false,
              isShowLocationsChart: false,
            }"
          />
        </div>
      </a-spin>
      <h2 id="brand_section" class="page-sub-title">Các thương hiệu bán chạy đối với {{keyText}}</h2>
      <SearchSummaryQuery v-if="hash && searchOptions" :search-options="searchOptions"/>
      <a-spin :spinning="isLoading">
        <div class="container-bg-white container-space-default border-solid">
          <MarketChart :show-chart="{
              isShowCategoriesChart: false,
              isShowPriceRangeChart: false,
              isShowBrandsChart: true,
              isShowLocationsChart: false,
            }"
          />
        </div>
      </a-spin>
      <h2 id="location_section" class="page-sub-title">Địa chỉ các shop bán chạy cho {{keyText}}</h2>
      <SearchSummaryQuery v-if="hash && searchOptions" :search-options="searchOptions"/>
      <a-spin :spinning="isLoading">
        <div class="container-bg-white container-space-default border-solid">
          <MarketChart :show-chart="{
              isShowCategoriesChart: false,
              isShowPriceRangeChart: false,
              isShowBrandsChart: false,
              isShowLocationsChart: true,
            }"
          />
        </div>
      </a-spin>
      <h2 id="download_section" class="page-sub-title">Tải file excel danh sách toàn bộ sản phẩm online bán chạy cho {{keyText}}</h2>
      <div class="share-report-button">
        <a-tooltip placement="bottom">
          <template slot="title">
            Tải danh sách sản phẩm (Excel)
          </template>
          <a-button id="download_csv" type="default" size="large" @click="onClickDownloadCSV">
            <a-icon type="download"/>
            Download danh sách
          </a-button>
        </a-tooltip>
        <BtnShareLink type="primary" :is-ghost="true" text="Chia sẻ phân tích"/>
      </div>
<!--      <PopupPermissionNotification/>-->
      <PopupSearchFilter/>
    </div>
    <BeeFooter/>
  </div>
</template>

<script>

import TableProduct from '@/views/search/TableProduct'
import MetricRectangle from '@/components/statistic/metric/MetricRectangle'
import { formatCurrency, formatNumberHuman } from '@/helper/FormatHelper'
import SearchFilter from '@/views/search/SearchFilter'
import { MutationSidebar } from '@/store/modules/SidebarModule/mutation'
import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
import {ActionNameConstants} from '@/constant/general/GeneralConstant'
import BarChart from '@/components/chart/BarChart'
import LineChart from '@/components/chart/LineChart'
import ColumnChart from '@/components/chart/ColumnChart'
import PieChart from '@/components/chart/PieChart'
import { getCustomQuery } from '@/service/user/UserProfileService'
import MarketChart from '@/views/market/MarketChart'
import StatisticGeneral from '@/views/market/StatisticGeneral'
import PopupShareCustomQuery from "@/views/popup/PopupShareCustomQuery";
import PopupPermissionNotification from "@/views/popup/PopupPermissionNotification";
import SearchSummaryQuery from "@/views/search/SearchSummaryQuery";
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
import LayoutLanding from "@/layout/LayoutLanding";
import Nav from "@/components/homepage/Nav";
import {canDownloadCSV} from "@/service/market/MarketService";
import PopupSearchFilter from "@/views/popup/PopupSearchFilter";
import BeeFooter from "@/components/partial/footer/BeeFooter";
import BtnSearchFilter from "@/components/button/BtnSearchFilter";
import BtnShareLink from "@/components/button/BtnShareLink";
import TableProductLite from "@/views/search/TableProductLite";
import {ALL_CATEGORIES_BY_PLATFORM} from '@/constant/product/ProductCategoriesConstant'
import {toNumber} from "@/helper/StringHelper";

export default {
  components: {
    BtnSearchFilter,
    Nav,
    BeeFooter,
    SearchSummaryQuery,
    LayoutLanding,
    SearchFilter,
    TableProduct,
    MetricRectangle,
    BarChart,
    LineChart,
    ColumnChart,
    PieChart,
    MarketChart,
    StatisticGeneral,
    PopupShareCustomQuery,
    PopupPermissionNotification,
    PopupSearchFilter,
    BtnShareLink,
    TableProductLite
  },
  data() {
    return {
      tabStatistic: 'revenue',
      hash: this.$route.params.hash.toString(),
      searchOptions: null,
      isBigScreen: screen.availWidth > 700
    }
  },
  async mounted() {
    if (typeof this.hash !== 'string') {
      return
    }
    this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isClickedSearched}`, true)
    this.searchOptions = await getCustomQuery(this.hash)
    if (!this.searchOptions) {
      this.$router.push({name: 'market_view'})
      return
    }
    await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, this.searchOptions)
  },
  created() {
    // update state for UI
    // Cập nhật trạng thái {menu, actionName} đang ở màn hình search
    this.$store.commit(`GeneralModule/${MutationGeneral.setActionName}`, ActionNameConstants.MARKET)
    this.$store.commit(`SidebarModule/${MutationSidebar.menuSelected}`, ActionNameConstants.MARKET)
  },
  methods: {
    async onClickDownloadCSV() {
      if (!this.hash) {
        return
      }
      let statusDownload = await canDownloadCSV(this.hash)
      if (!statusDownload) {
        this.$notification['error']({
          message: 'Đã xảy ra lỗi',
          description: 'Tệp tin đang được chuẩn bị, vui lòng thử lại sau 10 giây',
        })
        return
      }
      let downloadCSVDomElement = document.createElement('a')
      downloadCSVDomElement.href = `https://apiv2.beecost.com/market/custom_query?hash=${this.hash}&export=1`
      downloadCSVDomElement.click()
    },
    onClickGoToDownload() {
      let btnDownloadElem = document.getElementById('download_csv')
      btnDownloadElem?.scrollIntoView({behavior: "smooth"});
    }
  },
  computed: {
    durationDay() {
      return toNumber(this.searchOptions?.sort_by)
    },
    keyText() {
      if (!this.searchOptions) {
        return ''
      }
      let key = this.searchOptions.queries?.[0]
      if (key) {
        return `từ khoá "${key}"`
      }
      let categoryId = this.searchOptions.categories?.[0]
      if (categoryId) {
        // let platform = getPlatformById(this.iPlatforms[0])
        // let category = ProductCategoriesConstant.categoriesOption[platform.uniqueName].find( category => {
        let category = ALL_CATEGORIES_BY_PLATFORM.shopee.find(category => {
          return category.value === categoryId
        })?.label
        return `ngành hàng "${category}"`
      }
      return 'Tất cả ngành hàng'
    },
    iPlatforms: {
      get() {
        return this.$store.state.SearchFilterModule.iPlatforms
      }
    },
    isClickedSearched: {
      get() {
        return this.$store.state.SearchFilterModule.isClickedSearched
      }
    },
    isLoading: {
      get() {
        return this.$store.state.SearchFilterModule.isLoading
      }
    },
    orderTotal: {
      get() {
        return this.$store.state.SearchStatisticResultModule.orderTotal
      }
    },
    revenueTotal: {
      get() {
        return this.$store.state.SearchStatisticResultModule.revenueTotal
      }
    },
    productTotal: {
      get() {
        return this.$store.state.SearchStatisticResultModule.productTotal
      }
    },
    shopTotal: {
      get() {
        return this.$store.state.SearchStatisticResultModule.shopTotal
      }
    },
    aggRevenueByCategories: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggRevenueByCategories
      }
    },
    aggRevenueByPriceRange: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggRevenueByPriceRange
      }
    },
    aggRevenueByBrands: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggRevenueByBrands
      }
    },
    aggRevenueByLocations: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggRevenueByLocations
      }
    },

    aggOrderCountByCategories: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggOrderCountByCategories
      }
    },
    aggOrderCountByPriceRange: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggOrderCountByPriceRange
      }
    },
    aggOrderCountByBrands: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggOrderCountByBrands
      }
    },
    aggOrderCountByLocations: {
      get() {
        return this.$store.state.SearchStatisticResultModule.aggOrderCountByLocations
      }
    }
  },
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
  },
}
</script>

<style lang="scss">

.tab-filter-option {
  .ant-tabs-nav {
    display: flex;
    justify-content: space-between
  }

}

</style>

<style scoped lang="scss">

@media screen and (min-width: 1024px) {
  .page-content {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1216px) {
  .page-content {
    max-width: 1152px;
    margin: 0 auto;
  }
}

.page-title {
  font-size: 34px;
}

.page-sub-title {
  font-size: 26px;
  margin-top: 12px;
}

.page-description {
  font-size: 18px;
}

strong {
  font-size: 20px;
  margin-top: 8px;
}

.list-of-content {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;

  li {
    font-size: 16px;
    cursor: pointer;

    &:hover {
    text-decoration: underline;
  }
  }
}

.share-report-button {
  width: 100%;
  text-align: left;
  padding-left: 8px;
  margin-bottom: 20px;

  button {
    margin-left: 12px;
    margin-top: 4px;
  }
}
.market-chart {
  padding: 20px;
  border: solid 0.5px #cfd8dc66;
  margin-top: 20px;
  margin-bottom: 20px;
}

.go-to-download-wrap {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  margin-top: 4px;
  cursor: pointer;

  .go-to-download {
    width: fit-content;
    padding: 4px;
    border: #e8e8e8 1px solid;
  }
}
</style>
